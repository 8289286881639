import {Model} from "../vuemc";

export default class Forgot extends Model
{
    defaults() {
        return {
            email: null,
            captchaToken: null,
        }
    }
}
